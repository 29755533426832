<template>
  <div class="toolbar-section-separator">&nbsp;</div>
</template>
<style scoped>
  div.toolbar-section-separator {
    background-color: var(--toolbar-separator-color);
    min-width: 1px;
    max-width: 1px;
    width: 1px;
    margin: 0px 4px;
    overflow: hidden;
  }
</style>