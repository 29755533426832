<template>
  <div class="filemanager-list">
    <div class="p-3" v-if="isLoading">
      Loading ...
    </div>
    <div class="p-3" v-else-if="!sortedFiles || sortedFiles.length === 0">
      No Files Found for this Site.
    </div>
    <div class="filemanager-list-list" v-else-if="isList">
      <FileManagerListItem v-for="file of sortedFiles" :key="file.id"
        :file="file"
        :showEditorFunc="showEditorFunc"
        :autoView="fileId && file.id === fileId"
        :ref="'fileListItem-' + file.id"
      />
    </div>
    <div class="filemanager-list-thumbnails" v-else>
      <FileManagerThumbnail v-for="file of sortedFiles" :key="file.id" :file="file" :showEditorFunc="showEditorFunc" :autoView="fileId && file.id === fileId" />
    </div>
    <OmniViewer
      v-if="viewFile"
      :file="viewFile"
      :showEditorFunc="showEditorFunc"
      :closer="closeViewer"
      ref="mainListOmniViewer"
    />
  </div>
</template>
<script>
import FileManagerThumbnail from '@/components/controls/FileManager/FileManagerThumbnail.vue'
import FileManagerListItem from '@/components/controls/FileManager/FileManagerListItem.vue'
import OmniViewer from '@/components/controls/Viewers/OmniViewer.vue'

export default {
  name: 'file-manager-list',
  props: ['isLoading', 'viewMode', 'sortMode', 'fileTypeFilter', 'files', 'showEditorFunc', 'fileId'],
  components: { FileManagerThumbnail, FileManagerListItem, OmniViewer },
  data () {
    return {
      viewFile: undefined
    }
  },
  methods: {
    closeViewer () {
      this.viewFile = undefined
    },
    showViewerForFileId (fileId) {
      for (const file of this.files) {
        if (file && file.id === fileId) {
          this.viewFile = file
          this.$nextTick(() => {
            this.$refs.mainListOmniViewer.show()
          })
          break
        }
      }
    }
  },
  computed: {
    isList () {
      return this.viewMode === 'list'
    },
    sortedFiles () {
      if (!this.files || this.files.length === 0) {
        return []
      }

      // filter as needed
      const filterList = !this.fileTypeFilter ? [] : this.fileTypeFilter.split('|')
      const out = []
      for (const file of this.files) {
        if (filterList.includes('all')) {
          out.push(file)
          continue
        }
        if (['jpg', 'png'].includes(file.fileType) && filterList.includes('img')) {
          out.push(file)
          continue
        }
        if (['csv', 'tsv'].includes(file.fileType) && filterList.includes('csv')) {
          out.push(file)
          continue
        }
        if (['pdf'].includes(file.fileType) && filterList.includes('pdf')) {
          out.push(file)
          continue
        }
        if (['mp4'].includes(file.fileType) && filterList.includes('vid')) {
          out.push(file)
          continue
        }
      }

      // then sort
      return out.slice().sort((a, b) => {
        if (this.sortMode === 'name') {
          return a.filename.toLowerCase().localeCompare(b.filename.toLowerCase());
        } else {
          return b.createdAt - a.createdAt;
        }
      })
    }
  }
}
</script>
<style scoped>
  .filemanager-list {
    max-height: 530px;
    overflow-y: scroll;
  }
  .filemanager-list-thumbnails {
    margin: 15px 0px 5px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
</style>