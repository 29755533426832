<template>
  <div @click="finalClick" :class="classes">
    <fa :icon="finalIcon" class="icon-button-icon" :aria-label="alt" />
  </div>
</template>
<script>
export default {
  name: 'icon-button',
  props: ['icon', 'clickFunc', 'theme', 'sz', 'alt', 'wrapperClass'],
  computed: {
    classes () {
      const base = 'icon-button'
      let out = base
      if (this.theme) {
        out = `${out} ${base}-theme-${this.theme}`
      }
      if (this.sz) {
        out = `${out} ${base}-size-${this.sz}`
      }
      if (this.wrapperClass) {
        out = `${out} ${this.wrapperClass}`
      }
      return out
    },
    finalClick () {
      if (this.clickFunc) {
        return this.clickFunc
      }
      return () => {}
    },
    finalIcon () {
      if (this.icon) {
        return this.icon
      }
      return 'times'
    }
  }
}
</script>
<style scoped>

.icon-button .icon-button-icon {
  height: 20px;
  cursor: pointer;
  color: var(--app-link-color);
  margin-left: 25px;
  border-radius: 4px;
}

.icon-button.icon-button-size-sm .icon-button-icon {
  height: 18px;
  margin-left: 20px;
}

.icon-button.icon-button-theme-dark .icon-button-icon {
    color: #3399e8;
}

.icon-button:hover .icon-button-icon {
  filter: brightness(var(--app-hover-brightness)) drop-shadow(var(--icon-hover-drop-shadow));
}
</style>