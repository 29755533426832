<template>
  <div class="ss-control-container">
    <div class="ss-control-table-wrapper">
      <div v-if="isLoading" class="font-increase-2 bold p-4">
        Loading Data ...
      </div>
      <table class="ss-control-table" v-if="!isLoading && sortedRows.length > 0">
        <thead>
          <tr class="header">
            <th class="header-col" v-if="numberedRows" />
            <th v-for="(field,j) of headerRow" :key="j" :class="columnClass(j)" @click="setSort(j)">
              {{ field }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(line,i) of sortedRows" :key="i" v-bind:class="{'data-row-last': i+1 == sortedRows.length}">
            <td class="header-col" v-if="numberedRows">{{ i+1 }}</td>
            <td v-for="(field,j) of line" :key="j" :class="{'header-col': !numberedRows && j === 0}">
              {{ field }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      sort: this.initialSort,
      reverseSort: this.initialSortReverse
    }
  },
  name: 'spreadsheet-control',
  props: ['tsvData', 'rows', 'sheetTitle', 'numberedRows', 'isLoading', 'initialSort', 'initialSortReverse'],
  computed: {
    titleText () {
      return this.sheetTitle
    },
    computedRows () {
      if (this.rows) {
        return this.rows
      }
      if (!this.tsvData) {
        return []
      }
      const parsed = []
      const lines = this.tsvData.trim().split('\n')
      for (const line of lines) {
        const fields = line.split('\t')
        parsed.push(fields)
      }
      return parsed
    },
    headerRow () {
      if (!this.computedRows || this.computedRows.length === 0) {
        return null
      }
      return this.computedRows[0]
    },
    sortedRows () {
      if (!this.computedRows || this.computedRows.length === 0) {
        return null
      }
      const rows = [...this.computedRows]
      rows.shift()
      if (!this.sort && this.sort !== 0) {
        return rows
      }
      let sr = Array.from(rows)
      sr.sort(this.sortRows)
      if (this.reverseSort) {
        sr = sr.reverse()
      }
      return sr
    }
  },
  methods: {
    columnClass (col) {
      let classes = 'clickable sortable'
      if (!this.numberedRows && col === 0) {
        classes += ' header-col'
      }
      if (col === this.sort) {
        classes += ' col-sorted'
        classes = this.reverseSort ? classes + ' sorted-desc' : classes + ' sorted-asc'
      }
      return classes
    },
    setSort (sort) {
      if (!(sort >= 0)) {
        return
      }
      if (sort === this.sort) {
        this.reverseSort = !this.reverseSort
      } else {
        this.sort = sort
        this.reverseSort = false
      }
    },
    sortRows (a, b) {
      const a1 = a[this.sort] ? a[this.sort] : a[this.sort]
      const b1 = b[this.sort] ? b[this.sort] : b[this.sort]

      if (a1 === undefined || a1 === null) {
        if (b1 === undefined || b1 === null) {
          return 0
        }
        return -1
      }
      if (b1 === undefined || b1 === null) {
        return 1
      }
      if (a1 === b1) {
        return 0
      } else {
        return (a1 < b1) ? -1 : 1
      }
    }
  }
}
</script>
<style scoped>
    .ss-control-container {
      margin: 0px auto;
      background-color: var(--app-bg-color);
      border-radius: var(--form-border-radius);
      border: 1px var(--form-control-border-color) solid;
      box-shadow: 0 0 50px 40px var(--modal-shadow);
      transition: all 0.3s ease;
      max-height: calc(100vh - 70px);
      padding: 0px;
  }

  .ss-control-table-wrapper {
      max-height: calc(100vh - 70px);
      overflow: scroll;
      max-width: calc(100vw - 30px);
      width: calc(100vw - 30px);
      min-height: calc(100vh - 70px);
  }

  .ss-control-table {
      border: 1px var(--form-control-border-color) solid;
      font-size: var(--font-reduce-2);
      max-height: fit-content;
      margin: 0 auto;
      white-space: nowrap;
  }

  .ss-control-table tbody tr:nth-of-type(odd) {
      color: var(--app-text-stripe-color);
      background-color: var(--app-bg-stripe-color);
  }

  .ss-control-table tbody tr:nth-of-type(odd):hover {
      background-color: var(--form-row-hover);
  }

  .ss-control-table tbody tr:nth-of-type(even) {
      color: var(--app-text-color);
      background-color: var(--app-bg-color);
  }

  .ss-control-table tbody tr:nth-of-type(even):hover {
      background-color: var(--form-row-hover);
  }

  .ss-control-table th {
      background-color: var(--app-bg-color);
      color: var(--app-text-color);
      font-size: var(--font-reduce-3);
      padding: 4px 4px 4px 9px;
  }

  .ss-control-table td {
      padding: 4px 4px 4px 9px;
  }

  .ss-control-table tr {
      border-bottom: 1px var(--form-control-border-color) solid;
  }

  .ss-control-table tr.header {
      background-color: var(--app-bg-color);
      border-bottom: 1px var(--form-control-border-color) solid;
  }

  .ss-control-table tr.header th {
      background-color: var(--form-row-hover);
      position: sticky;
      top: 0px;
      box-shadow: inset 0 -1px 0 var(--form-control-border-color);
  }

  .ss-control-table .header-col {
      background-color: var(--form-row-hover) !important;
      border-right: 1px var(--form-control-border-color) solid;
      position: sticky;
      color: var(--app-text-color) !important;
      text-align: center;
      font-weight: 600;
      padding: 0px;
      min-width: 48px;
      z-index: 998;
      box-shadow: inset -1px 0 0 var(--form-control-border-color);
  }

  .ss-control-table tr.header th.header-col {
      left: 0px;
      top: 0px;
      z-index: 999;
      box-shadow: inset -1px 0 0 var(--form-control-border-color),
          inset 0 -1px 0 var(--form-control-border-color);
  }

  .ss-control-table td.header-col {
      left: 0px;
  }

  .ss-control-table tbody tr:nth-of-type(odd) td.header-col {
      color: var(--app-text-stripe-color);
      background-color: var(--app-bg-stripe-color);
  }

  .ss-control-table tbody tr:nth-of-type(odd) td.header-col:hover {
      background-color: var(--form-row-hover);
  }

  .ss-control-table tbody tr:nth-of-type(even) td.header-col {
      color: var(--app-text-color);
      background-color: var(--app-bg-color);
  }

  .ss-control-table tbody tr:nth-of-type(even) td.header-col:hover {
      background-color: var(--form-row-hover);
  }

  .ss-control-table tr:hover {
      background-color: var(--form-row-hover);
  }

  .ss-control-table th.col-sorted {
    color: var(--app-sort-color);
    background-color: var(--app-sort-bg-color);
    fill: var(--app-sort-color);
  }

  .ss-control-table th.col-sorted.sorted-desc {
    margin-top: -1.5px;
    border-top: 1.5px var(--app-sort-border-color) solid !important;
  }

  .ss-control-table th.col-sorted.sorted-asc {
    margin-bottom: -1.5px;
    border-bottom: 1.5px var(--app-sort-border-color) solid !important;
  }

</style>