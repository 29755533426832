<template>
  <transition name="modal">
  <ModalMediaViewer v-if="isBlocking && file" @close="close" sz="auto">
      <template v-slot:body>
        <div class="spreadsheet-viewer-body">
          <ViewerTopBar :file="file" :showEditorFunc="showEditorFunc" :closer="close" />
          <div class="spreadsheet-viewbox">
            <Spreadsheet
              ref="spreadsheet"
              :rows="rows"
              :isLoading="isLoading"
              :initialSort="0"
              :initialSortReverse="false"
              :numberedRows="true"
              v-if="rows"
            />
          </div>
          <ViewerBottomBar :file="file" />
        </div>
      </template>
  </ModalMediaViewer>
  </transition>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import ModalMediaViewer from '@/components/controls/Viewers/ModalMediaViewer.vue'
import Spreadsheet from '@/components/tables/Spreadsheet.vue'
import IconButton from '@/components/controls/IconButton.vue'
import ViewerTopBar from '@/components/controls/Viewers/ViewerTopBar.vue'
import ViewerBottomBar from '@/components/controls/Viewers/ViewerBottomBar.vue'

export default {
  name: 'spreadsheet-viewer',
  extends: BaseComponent,
  components: { ModalMediaViewer, IconButton, Spreadsheet, ViewerTopBar, ViewerBottomBar },
  props: ['file', 'showEditorFunc', 'closer'],
  data () {
    return {
      isBlocking: false,
      isLoading: false,
      rawData: undefined
    }
  },
  computed: {
    rows () {
      if (!this.file || !this.file.filename || !this.rawData) {
        return []
      }
      if (this.file.filename.toLowerCase().endsWith('.tsv')) {
        return this.parseTSV(this.rawData)
      }
      return this.parseCSV(this.rawData)
    }
  },
  methods: {
    loadData () {
      if (!this.file || !this.file.s3url) {
        return
      }
      this.isLoading = true
      fetch(this.file.s3url)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error(`Error: ${response.statusText}`)
        }
        resp.text()
        .then(txt => {
          this.rawData = txt
        })
        .catch(e => {
          console.log('failed to load file', this.file.filename, e)
        })
        .finally(() => {
          this.isLoading = false
        })
      })
      .catch(e => {
        console.log('failed to load file', this.file.filename, e)
        this.isLoading = false
      })
    },
    show () {
      this.rawData = undefined
      this.isBlocking = true
      this.loadData()
    },
    close () {
      this.isBlocking = false
      if (this.closer) {
        this.closer()
      }
    },
    parseTSV (tsvString) {
        const out = []
        const lines = tsvString.trim().split('\n')
        for (const line of lines) {
          const fields = line.split('\t')
          out.push(fields)
        }
        return out
    },
    parseCSV (csvString) {
      const rows = csvString.trim().split('\n')
      const matrix = rows.map(row => {
        const cells = []
        let cell = ''
        let insideQuotes = false
        
        for (let i = 0; i < row.length; i++) {
          const char = row[i]
          
          if (char === '"' && (i === 0 || row[i-1] !== '\\')) {
            insideQuotes = !insideQuotes
          } else if (char === ',' && !insideQuotes) {
            cells.push(cell.trim())
            cell = ''
          } else {
            cell += char
          }
        }
        
        cells.push(cell.trim())
        return cells;
      })
      return matrix;
    }
  }
}
</script>
<style scoped>
  .spreadsheet-viewer-body {
    position: relative;
  }

  .spreadsheet-viewbox {
    margin: 0 auto;
    text-align: center;
  }

</style>