<template>
  <div class="row form-group">
    <slot name="label" v-if="label">
      <label :class="labelClasses" :for="id">{{ label }}</label>
    </slot>
    <div class="col-auto form-right-input">
      <slot name="input" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'id', 'modelValue', 'labelClass', 'inputStyle', 'inputClass',
    'maxlength', 'placeholder', 'required', 'readonly', 'disabled'],
  emits: ['update:modelValue'],
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    inputClasses () {
      let out = 'form-input text-input'
      if (this.inputClass) {
        out += ` ${this.inputClass}`
      }
      return out
    },
    labelClasses () {
      let out = 'col-form-label form-left-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
