<template>
  <transition name="modal">
  <ModalMediaViewer v-if="isBlocking && file" @close="close" sz="auto">
      <template v-slot:body>
        <div class="log-viewer-body">
          <ViewerTopBar :file="file" :showEditorFunc="showEditorFunc" :closer="close" />
          <div class="log-viewbox">
            <textarea v-if="logData" v-model="logData" />
          </div>
          <ViewerBottomBar :file="file" />
        </div>
      </template>
  </ModalMediaViewer>
  </transition>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import ModalMediaViewer from '@/components/controls/Viewers/ModalMediaViewer.vue'
import IconButton from '@/components/controls/IconButton.vue'
import ViewerTopBar from '@/components/controls/Viewers/ViewerTopBar.vue'
import ViewerBottomBar from '@/components/controls/Viewers/ViewerBottomBar.vue'

export default {
  name: 'log-viewer',
  extends: BaseComponent,
  components: { ModalMediaViewer, IconButton, ViewerTopBar, ViewerBottomBar },
  props: ['file', 'showEditorFunc', 'closer'],
  data () {
    return {
      isBlocking: false,
      isLoading: false,
      logData: undefined
    }
  },
  methods: {
    loadData () {
      if (!this.file || !this.file.s3url) {
        return
      }
      this.isLoading = true
      fetch(this.file.s3url)
      .then((resp) => {
        if (!resp.ok) {
          throw new Error(`Error: ${response.statusText}`)
        }
        resp.text()
        .then(txt => {
          this.logData = txt
        })
        .catch(e => {
          console.log('failed to load file', this.file.filename, e)
        })
        .finally(() => {
          this.isLoading = false
        })
      })
      .catch(e => {
        console.log('failed to load file', this.file.filename, e)
        this.isLoading = false
      })
    },
    show () {
      this.logData = undefined
      this.isBlocking = true
      this.loadData()
    },
    close () {
      this.isBlocking = false
      if (this.closer) {
        this.closer()
      }
    }
  }
}
</script>
<style scoped>
  .log-viewer-body {
    position: relative;
  }

  .log-viewbox {
    margin: 0 auto;
    text-align: center;
  }

  .log-viewbox textarea {
    min-width: 375px;
    width: 1450px;
    max-width: calc(100vw - 20px);
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
  }


</style>