<template>
    <div class="vtb-bottom-row" v-if="rowText">
      <div class="vtb-bottom-text">
        {{ rowText }}
      </div>
    </div>
</template>
<script>
export default {
  name: 'file-viewer-bottom-row',
  props: ['file'],
  computed: {
    rowText () {
      if (!this.file) {
        return ''
      }
      const parts = []
      if (this.file.createdBy) {
        parts.push(this.file.createdBy)
      }
      if (this.file.createdAt) {
        const date = new Date(this.file.createdAt * 1000);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        parts.push(`${year}-${month}-${day} ${hours}:${minutes} UTC`)
      }
      if (this.file.description) {
        parts.push(this.file.description)
      }
      if (parts.length === 0) {
        return ''
      }
      return parts.join('  •  ')
    }
  },
  methods: {}
}
</script>
<style scoped>
  .vtb-bottom-row {
    min-height: 35px;
    margin-left: 5px;
    max-width: calc(100vw - 15px);
    white-space: normal;
  }

  .vtb-bottom-text {
    font-size: var(--font-reduce-2);
    color: #999;
  }
</style>