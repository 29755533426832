<template>
  <transition name="modal">
  <ModalMediaViewer v-if="isBlocking" @close="close" sz="auto">
      <template v-slot:body>
        <div class="pdf-viewer-body">
          <ViewerTopBar :file="file" :showEditorFunc="showEditorFunc" :closer="close" />
          <div class="pdf-viewbox">
            <embed :src="file.s3url" v-if="file && file.s3url" :alt="file.filename" crossOrigin="anonymous" type="application/pdf" class />
          </div>
          <ViewerBottomBar :file="file" />
        </div>
      </template>
  </ModalMediaViewer>
  </transition>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import ModalMediaViewer from '@/components/controls/Viewers/ModalMediaViewer.vue'
import IconButton from '@/components/controls/IconButton.vue'
import BasicButton from '@/components/controls/BasicButton.vue'
import ViewerTopBar from '@/components/controls/Viewers/ViewerTopBar.vue'
import ViewerBottomBar from '@/components/controls/Viewers/ViewerBottomBar.vue'

export default {
  name: 'pdf-viewer',
  extends: BaseComponent,
  components: { ModalMediaViewer, BasicButton, IconButton, ViewerTopBar, ViewerBottomBar },
  props: ['file', 'showEditorFunc', 'closer'],
  data () {
    return {
      isBlocking: false,
      canvas: undefined
    }
  },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
      if (this.closer) {
        this.closer()
      }
    },
  }
}
</script>
<style scoped>
  .pdf-viewer-body {
    position: relative;
  }

  .pdf-viewbox {
    margin: 0 auto;
    text-align: center;
  }

  .pdf-viewbox embed {
    border: 1px solid black;
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    min-width: calc(100vw - 20px);
    background-color: #333;
  }

</style>