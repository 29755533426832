<template>
  <div :class="classes">
    <slot name="sections" />
  </div>
</template>
<script>
export default {
  name: 'toolbar-control',
  computed: {
    classes () {
      return "toolbar toolbar-border-bottom"
    }
  }
}
</script>
<style scoped>
  .toolbar {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  .toolbar.toolbar-border-bottom {
    border-bottom: 1px var(--form-control-border-color) solid;
  }
</style>
