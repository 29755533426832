<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container modal-viewer">
        <div :class="{'scrollable-modal': isScrollable}" v-if="!isHelpVisible">
          <div>
            <slot name="body" />
          </div>
        </div>
        <div class="modal-body mx-2" v-else>
          <pre class="help-copy">{{ helpCopy }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Reloadables from '@/services/reloadables'

export default {
  name: 'modal-viewer',
  props: ['reloadable', 'isScrollable', 'bodyClass', 'helpCopy', 'canClose'],
  data () {
    return {
      ct: -1,
      closeTime: '',
      userClosed: false,
      timedMessage: '',
      isHelpVisible: false,
      width: window.innerWidth
    }
  },
  computed: {
    displayedMessage () {
      if (this.timedMessage) {
        return this.timedMessage
      }
      if (this.message) {
        return this.message
      }
      return ''
    },
    wrapperClass () {
      return 'modal-wrapper'
    },
  },
  methods: {
    setIsHelpVisible (t) {
      this.isHelpVisible = t === true ? true : false
    },
    checkWidth () {
      if (this.userClosed || this.sz !== 'auto') {
        return
      }
      this.width = window.innerWidth
      setTimeout(this.checkWidth, 100)
    },
    requestReload () {
      Reloadables.requestReload()
    },
    remainingCloseTime () {
      return this.closeTime
    },
    startTimedClosed (secs) {
      this.ct = secs
      this.closeTime = secs.toString()
      if (this.ct < 0) {
        return
      }
      this.timedClose()
    },
    timedClose () {
      if (this.userClosed) {
        return
      }
      if (this.ct >= 1) {
        this.closeTime = this.ct.toString()
        this.ct -= 1
        this.$emit('updateCloseTime')
        setTimeout(this.timedClose, 1000)
        return
      }
      if (this) {
        this.closeTime = ''
        this.$emit('close')
      }
    },
    handleKeyPress (e) {
      if (e.code === 'Escape' && this.canClose !== false) {
        this.$emit('close')
      }
    },
    close (requestReload = false) {
      if (requestReload) {
        this.requestReload()
      }
      this.userClosed = true
      document.removeEventListener('keydown', this.handleKeyPress)
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyPress)
    this.checkWidth()
  },
  unmounted () {
    document.removeEventListener('keydown', this.handleKeyPress)
    this.userClosed = true
  }
}
</script>
<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 1, 2, .93);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
  }

  .modal-container {
    margin: 0px auto;
    transition: all 0.3s ease;
  }

  .modal-viewer {
    max-width: calc(100vw - 20px);
    width: fit-content;
    min-width: fit-content;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }

</style>
