/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import IndexRouter from '../router/index';

const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;

var authData = {
    ClientId : CLIENT_ID, // Your client id here
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['openid', 'email'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID,
}

var auth = new CognitoAuth(authData);
auth.userhandler = {
    onSuccess: function(result) {},
    onFailure: function(err) {
        IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
    }
};

function getUserInfoStorageKey(){
    var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
    var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
    var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default{
    userPrivs: null,
    userRoles: null,
    auth: auth,
    login(){
        auth.getSession();
    },
    logout(){
        if (auth.isUserSignedIn()) {
            var userInfoKey = this.getUserInfoStorageKey();
            storage.removeItem(userInfoKey);
            auth.signOut()
        }
    },
    userHasAnyPrivilege(){
        return (this.userPrivs && this.userPrivs.length > 0) ||
            (this.userRoles && this.userRoles.length > 0)
    },
    userHasPrivilegeSubjLike(subjPrefixes){
        if (!this.userPrivs) {
            return false
        }
        for (const priv of this.userPrivs) {
            for (const prefix of subjPrefixes) {
                if ((priv.roleID === 'hub-admin') ||
                    (priv.roleID === 'hub-viewer') ||
                    (priv.subject.startsWith(prefix))) {
                    return true
                }
            }
        }
        return false
    },
    userHasPrivilegeSubject(subjects){
        if (!this.userPrivs) {
            return false
        }
        for (const priv of this.userPrivs) {
            for (const subject of subjects) {
                if ((priv.roleID === 'hub-admin') ||
                    (priv.roleID === 'hub-viewer') ||
                    (priv.subject === subject)) {
                    return true
                }
            }
        }
        return false
    },
    userHasPrivilege(subj, verb, cust){
        if (!this.userPrivs) {
            return false
        }
        const verbs = [verb]
        if (verb === 'view') {
            verbs.push('edit')
            verbs.push('update')
            verbs.push('create')
        } else if (verb === 'update') {
            verbs.push('edit')
        } else if (verb === 'create') {
            verbs.push('edit')
        }

        for (const priv of this.userPrivs) {
            if (
                (priv.subject === subj || priv.subject === '*' || subj === '*') &&
                (verbs.includes(priv.verb) || priv.verb === '*' || verb === '*') &&
                (priv.customer === cust || priv.customer === '*' || cust === '*')
            ) {
                return true
            }
        }
        return false
    },
    userHasRole(role){
        if (!this.userRoles) {
            return false
        }
        for (const r of this.userRoles) {
            if ( r === role ){
                return true
            }
        }
        return false
    },
    userHasCustomer(cust){
        if (this.isGlobalViewer()) {
            return true
        }
        if (this.userPrivs == null) {
            return false
        }
        for (const p of this.userPrivs) {
            if ( p.customer === cust ){
                return true
            }
        }
        return false
    },
    isAdmin () {
        return this.userHasRole('hub-admin')
    },
    isGlobalViewer () {
        return this.userHasRole('hub-viewer') || this.userHasRole('hub-admin')
    },
    isMLDeveloper () {
        return this.userHasPrivilegeSubjLike(['ml-'])
    },
    hasNotificationPerms () {
        return this.userHasPrivilegeSubject(
            ['notif-exclusion-zones',
            'subscriptions',
            'alerts',
            'templates',
            'thresholds'])
    },
    hasFleetPerms () {
        return this.userHasPrivilegeSubject(
            ['devices',
            'nodes',
            'gateways',
            'customers',
            'sites'])
    },
    getUserInfoStorageKey,

}