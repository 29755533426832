<template>
    <div class="vtb-top-row">
      <div class="vtb-title">
        <span>
          {{ finalFilename }}
        </span>
      </div>
      <div class="vtb-controls">
        <IconButton icon="edit" :clickFunc="() => { showEditorFunc(file); close() }" theme="dark" v-if="showEditorFunc && file" />
        <IconButton icon="download" :clickFunc="() => { startDownload() }" theme="dark" />
        <IconButton icon="times" :clickFunc="() => { close() }" theme="dark" />
      </div>
    </div>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import IconButton from '@/components/controls/IconButton.vue'

export default {
  name: 'file-viewer-top-row',
  components: { IconButton },
  extends: BaseComponent,
  props: ['file', 'showEditorFunc', 'closer'],
  computed: {
    finalFilename () {
      if (!this.file) {
        return 'unnamed-file'
      }
      if (this.file.filename) {
        return this.file.filename.split("/").pop()
      }
      if (this.file.fileType) {
        return `unnamed-file.${this.file.fileType}`
      }
      return 'unnamed-file'
    },
  },
  methods: {
    close () {
      if (this.closer) {
        this.closer()
      }
    },
    startDownload () {
      if (this.file && this.file.s3url) {
        this.downloadFile(this.file.s3url, this.finalFilename)
      }
    }
  }

}
</script>
<style scoped>
  .vtb-top-row {
    display: flex;
    justify-content: space-between;
    min-height: 35px;
    max-height: 35px;
    align-items: center;
    margin-left: 5px;
    max-width: calc(100vw - 15px);
  }

  .vtb-controls {
    min-height: 26px;
    max-height: 26px;
    align-items: right;
    align-content: right;
    text-align: right;
    margin-bottom: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .vtb-title {
    color: #ccc;
    font-size: var(--font-reduce-2);
    margin-left: 3px;
  }

</style>