<template>
  <div :class="wrapperClasses">
    <span class="pillbox-label" v-if="label">{{ label }}</span>
    <div class="btn-group" role="group" :aria-label="label">
      <template v-for="pill of pills" :key="pill">
        <input type="radio" class="btn-check" :value="pillValue(pill)" :name="groupName"
          :id="pillId(pill)" v-model="activePill" autocomplete="off" @click="pillElementClicked" />
        <label class="btn btn-outline-primary" :for="pillId(pill)">&nbsp;{{ pillLabel(pill) }}&nbsp;</label>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pillbox',
  props: ['label', 'group', 'pills', 'defaultPill', 'activePillChanged', 'wrapperClass'],
  data () {
    return {
      activePill: this.defaultPill || ''
    }
  },
  computed: {
    groupName () {
      if (this.group) {
        return this.group
      }
      if (this.label) {
        return this.label.replaceAll(' ', '').toLowerCase()
      }
      return 'pillbox'
    },
    wrapperClasses () {
      const classes = "d-flex align-items-center"
      if (this.wrapperClass) {
        return `${classes} ${this.wrapperClass}`
      }
      return classes
    }
  },
  methods: {
    pillLabel (pill) {
      if (pill.label) {
        return pill.label
      }
      return "No Label"
    },
    pillId (pill) {
      if (pill.id) {
        return pill.id
      }
      return this.pillLabel(pill).replaceAll(' ', '').toLowerCase()
    },
    pillValue (pill) {
      if (pill.value) {
        return pill.value
      }
      return this.pillId(pill)
    },
    pillClicked (value) {
      if (value){
        this.activePill = value
        if (this.activePillChanged) {
          this.activePillChanged(value)
        }
      }
    },
    pillElementClicked (e) {
      if (e && e.target && e.target.value){
        this.activePill = e.target.value
        if (this.activePillChanged) {
          this.activePillChanged(e.target.value)
        }
      }
    }
  }
}
</script>
<style scoped>
  .pillbox-label {
    font-size: var(--font-reduce-2);
    font-weight: 600;
    margin-right: 7.5px;
  }
</style>