<template>
  <div :class="wrapperClasses">
    <div class="info-pane-title-row" v-if="title">
      <h5 class="dt-title" v-if="title">{{ title }}</h5>
      <slot name="filter-pillbox">
      </slot>
      <div>
        <slot name="action-icon">
          <button v-if="actionFunc" @click="actionFunc()" class="btn btn-table-header" style="margin-top:2px;margin-right:2px;" :disabled="actionDisabled">
            <fa :icon="icon" class="button-icon-wrapped" style="margin-top:-2px;margin-right: 3px;" />
            <span>
              {{ buttonLabel }}
            </span>
          </button>
        </slot>
      </div>
    </div>
    <div :class="paneClasses">
      <div>
        <slot name="info" />
      </div>
    </div>
  </div>
</template>
<script>
import { UpperFirstChar } from '@/lib/strings'

export default {
  props: ['title', 'actionFunc', 'actionIcon', 'actionLabel', 'itemType',
    'wrapperClass', 'paneClass', 'actionDisabled', 'wide'],
  computed: {
    buttonLabel () {
      if (this.actionLabel) {
        return this.actionLabel
      }
      if (this.itemType) {
        return `Edit ${UpperFirstChar(this.itemType)}`
      }
      return `Edit`
    },
    wrapperClasses () {
      let classNames = 'info-pane'
      if (this.wide) {
        classNames  += ' info-pane-wide'
      }
      if (this.wrapperClass) {
        classNames += ` ${this.wrapperClass}`
      }
      return classNames
    },
    paneClasses () {
      let classNames = ''
      if (this.paneClass) {
        classNames += ` ${this.paneClass}`
      }
      return classNames
    },
    icon () {
      if (this.actionIcon) {
        return this.actionIcon
      }
      return 'pen-to-square'
    }
  }
}
</script>