<script>
import HelpContent from '@/services/HelpContent'
import { deviceUplinkColors } from '@/app/data/deviceColors'
import NavigationService from '../services/NavigationService'
import auth from '@/app/auth'
import moment from 'moment'

export default {
  name: 'base-component',
  data () {
    return {
      id: '',
      status: '',
      message: '',
      isError: false,
      inEditMode: false,
      majorError: '',
      isLoading: false
    }
  },
  computed: {
    userHasAnyPrivilege () {
      return auth.userHasAnyPrivilege()
    },
    isAdmin () {
      return auth.isAdmin()
    },
    isMLDeveloper () {
      return auth.isMLDeveloper()
    },
    hasNotificationPerms () {
      return auth.hasNotificationPerms()
    },
    hasFleetPerms () {
      return auth.hasFleetPerms()
    }
  },
  methods: {
    downloadFile (remoteURL, localFileName) {
      fetch(remoteURL)
      .then(resp => {
        if (!resp.ok) {
          throw new error('Failed to fetch file', remoteURL)
        }
        return resp.blob()
      })
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.setAttribute('href', url)
        link.setAttribute('download', localFileName)
        link.setAttribute('crossOrigin', 'anonymous')
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch(e => {
        let message = 'Failed to Download ' + localFileName
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
    },
    formatTz (tz) {
      switch (tz) {
        case 'America/New_York':
          return 'US Eastern'
        case 'America/Chicago':
          return 'US Central'
        case 'America/Denver':
          return 'US Mountain'
        case 'America/Los_Angeles':
          return 'US Pacific'
        case 'America/Puerto_Rico':
          return 'US Atlantic (PR)'
        case 'utc':
          return 'UTC'
      }
      return tz
    },
    isTimeNotable (inputMS) {
      if (!inputMS) {
        return false
      } // alert if age > 4h
      return inputMS > 0 && (Date.now() - inputMS) <= (60000)
    },
    isTimeAlertable (inputMS, deviceType = '') {
      if (!inputMS) {
        return false
      }
      let thresh = 3600000 * 4
      if (deviceType && deviceType in deviceUplinkColors) {
        thresh = deviceUplinkColors[deviceType] * 1000 * 4
      } 
      // alert if age > 4h
      return inputMS > 0 && (Date.now() - inputMS) > (thresh)
    },
    isTimeCautionable (inputMS, deviceType = '') {
      if (!inputMS || inputMS <= 0) {
        return false
      }
      let thresh = 3600000
      if (deviceType && deviceType in deviceUplinkColors) {
        thresh = deviceUplinkColors[deviceType] * 1000
      }
      // caution if age > thresh
      const age = (Date.now() - inputMS)
      return age > (thresh) && age <= (4 * thresh)
    },
    truncate (input, length, suffix) {
      if (!input || input === '' || input.length <= length) {
        return input
      }
      return input.substring(0, length) + suffix
    },
    getFormattedDateMinUTC (input) {
      var epoch = parseInt(input)
      if (isNaN(epoch)) {
        return '--'
      }
      const d = new Date(epoch)
      const options = {
        timeZone: 'Etc/UTC',
        hour12: false,
        hour: 'numeric',
        minute: '2-digit',
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      }
      return `${d.toLocaleTimeString('en-US', options).replaceAll(', ', ' ')} UTC`
    },
    getFormattedDateSecs (input) {
      var epoch = parseInt(input)
      if (isNaN(epoch)) {
        return '--'
      }
      return this.getFormattedDate(epoch * 1000)
    },
    getFormattedDateSecsN (input) {
      return this.getFormattedDate(input * 1000)
    },
    getFormattedDate (input) {
      var epoch = parseInt(input)
      if (isNaN(epoch)) {
        return '--'
      }
      const d = new Date(epoch)
      const tzName = d.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop()
      return moment(d).format('YYYY-MM-DD HH:mm:ss') + ' ' + tzName
    },
    getLastWordIfUUID (input, sep) {
      if (input === '' || !input || input.length !== 36) {
        return input
      }
      if (!sep) {
        sep = '-'
      }
      const i = input.lastIndexOf(sep)
      if (i !== 23) {
        return input
      }
      return input.substring(i + 1)
    },
    getLastWord (input, sep) {
      if (input === '' || !input) {
        return input
      }
      if (!sep) {
        sep = '-'
      }
      const i = input.lastIndexOf(sep)
      if (i === -1) {
        return input
      }
      return input.substring(i + 1)
    },
    getLast10 (input) {
      return this.getLastN(input, 10)
    },
    getLast4 (input) {
      return this.getLastN(input, 4)
    },
    getLastN (input, len) {
      if (input === '') {
        return input
      }
      return input.substring(input.length - len)
    },
    getSinceSecs (input) {
      if (!input) {
        return '--'
      }
      var epoch = parseInt(input)
      if (isNaN(epoch)) {
        return '--'
      }
      return this.getSince(epoch * 1000)
    },
    getForUntil (input) {
      if (!input) {
        return '--'
      }
      var secs = Math.floor((parseInt(input) - Date.now()) / 1000)
      if (isNaN(secs)) {
        return '--'
      }
      if (secs < 60) {
        return secs.toString() + 's'
      }
      if (secs < 3600) {
        return Math.floor((secs / 60)).toString() + 'm'
      }
      if (secs < 86400) {
        return Math.floor((secs / 3600)).toString() + 'h'
      }
      if (secs < 604800) {
        return Math.floor((secs / 86400)).toString() + 'd'
      }
      if (secs < 31536000) {
        return Math.floor((secs / 604800)).toString() + 'w'
      }
      return Math.floor((secs / 31536000)).toString() + 'y'
    },
    getSince (input) {
      if (!input) {
        return '--'
      }
      var secs = Math.floor((Date.now() - parseInt(input)) / 1000)
      if (isNaN(secs)) {
        return '--'
      }
      if (secs < 60) {
        return secs.toString() + 's'
      }
      if (secs < 3600) {
        return Math.floor((secs / 60)).toString() + 'm'
      }
      if (secs < 86400) {
        return Math.floor((secs / 3600)).toString() + 'h'
      }
      if (secs < 604800) {
        return Math.floor((secs / 86400)).toString() + 'd'
      }
      if (secs < 31536000) {
        return Math.floor((secs / 604800)).toString() + 'w'
      }
      return Math.floor((secs / 31536000)).toString() + 'y'
    },
    setNav (l1, l2, l3, l4, u1, u2, u3, u4) {
      NavigationService.setNavLevel4(l1, l2, l3, l4, u1, u2, u3, u4)
    },
    handleError (e, objType) {
      if (!e) {
        return
      }
      this.isError = true
      console.log(e)
      if (!objType) {
        objType = 'Item'
      }
      if (e.response) {
        if (e.response.status === 404) {
          this.majorError = objType + ' Not Found'
        } else if (e.response.status === 400) {
          this.majorError = 'There was a problem with your request'
        } else {
          this.majorError = 'We\'re sorry, something went wrong.'
        }
      }
      this.setNav('', '', '', '', '', '', '', '')
    }
  },
  mounted () {
    if (!this.$route.name.toString().endsWith('-add')) {
      this.$state.creationName = ''
    }
  }
}
</script>

<style>
</style>
