<template>
  <div class="fm-list-item-row" v-if="file">
    <div class="fm-list-item-filename">
      {{file.filename}}
      <span v-if="file.createdAt" class="subtle ms-2">{{ getSinceSecs(file.createdAt)}}</span>
    </div>
    <div class="fm-list-item-controls">
      <IconButton :wrapperClass="file.fileType === 'pdf' ? 'd-none d-md-block d-lg-block d-xl-block' : ''"  icon="eye" :clickFunc="showViewer" v-if="viewer && viewer !== 'none'" sz="sm" style="margin-top: -2px;" alt="View File" />
      <IconButton icon="download" :clickFunc="startDownload" sz="sm" style="margin-left: 5px; margin-top: -3px;" alt="Download File" />
      <IconButton icon="edit" :clickFunc="() => { showEditorFunc(file) }" v-if="showEditorFunc && canEdit" sz="sm" style="margin-left: 5px; margin-top: -2px;" alt="Edit File Properties" />
    </div>
  </div>
  <OmniViewer
    :file="file"
    :showEditorFunc="showEditorFunc"
    ref="listItemOmniViewer"
  />
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import OmniViewer from '@/components/controls/Viewers/OmniViewer.vue'
import IconButton from '@/components/controls/IconButton.vue'

export default {
  name: 'file-manager-list-item',
  props: ['file', 'showEditorFunc', 'autoView'],
  extends: BaseComponent,
  components: { OmniViewer, IconButton },
  data () {
    return {
      viewerVisible: false
    }
  },
  methods: {
    startDownload () {
      if (!this.file || !this.file.fileType || !this.file.filename || !this.file.s3url) {
        return
      }
      this.downloadFile(this.file.s3url, this.file.filename)
    },
    showViewer () {
      this.$refs.listItemOmniViewer.show()
    }
  },
  computed: {
    canEdit () {
      return true
    },
    viewer () {
      if (this.isImage) {
        return 'image'
      }
      if (this.isVideo) {
        return 'video'
      }
      if (['csv', 'tsv'].includes(this.file.fileType)) {
        return 'csv'
      }
      if (this.file.fileType === 'pdf') {
        return 'pdf'
      }
      return 'none'
    },
    isImage () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['png', 'heic', 'jpg'].includes(this.file.fileType)
    },
    isVideo () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['mp4', 'mov'].includes(this.file.fileType)
    },
    placeholderIcon () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return 'circle-question'
      }
      if (this.isImage) {
        return 'image'
      }
      if (['csv', 'tsv'].includes(this.file.fileType)) {
        return 'file-csv'
      }
      if (this.file.fileType === 'pdf') {
        return 'file-pdf'
      }
      return 'circle-question'
    }
  },
  mounted () {
    if (this.autoView) {
      this.$nextTick(() => {
        this.showViewer()
      })
    }
  }
}
</script>
<style scoped>
.fm-list-item-row {
  border-bottom: 1px var(--form-control-border-color) solid;
  padding: 9.5px;
  font-size: var(--font-reduce-1);
  display: flex;
  justify-content: space-between;
}
.fm-list-item-row:last-of-type {
  border-bottom: none;
}

.fm-list-item-row:hover {
    background-color: var(--form-row-hover);
}

.fm-list-item-filename {
  overflow: hidden;
}

.fm-list-item-controls {
  display: flex;
}

.fm-list-item-control {
  display: flex;
  margin-right: 11px;
  font-size: var(--font-reduce-2);
  color: var(--app-link-color);
  text-decoration: none;
  cursor: pointer;
}

.fm-list-item-control:hover {
  text-decoration: underline;
}

.fm-list-item-control:first-of-type {
  margin-left: 10px;
}

.fm-list-item-control:last-of-type {
  margin-right: 0px;
}

</style>