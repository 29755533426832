import http from '@/http-common'

class FileManagementService {
  startNewFileWorkflow (customerId: string, data: any) {
    return http.post(`/provisioning/customer-files/${customerId}`, data)
  }

  updateCustomerFileWorkflow (customerId: string, id: string, data: any) {
    return http.post(`/provisioning/customer-files/${customerId}/${id}`, data)
  }

  uploadFile (url: string, data: ArrayBuffer, contentType: string, method = 'PUT') {
    const headers = {} as any
    if (contentType) {
      headers['Content-Type'] = contentType
    }
    return fetch(url, {
      method: method,
      body: data,
      headers: headers
    })
  }

  listCustomerFilesBySite (customerId: string, siteId: string) {
    return http.get(`/provisioning/customer-files/${customerId}/site/${siteId}`)
  }

  updateCustomerFile(customerId: string, id: string, data: any) {
    return http.put(`/provisioning/customer-files/${customerId}/${id}`, data)
  }

  deleteCustomerFile(customerId: string, id: string) {
    return http.delete(`/provisioning/customer-files/${customerId}/${id}`)
  }

  getContentType(url: string): string {
    url = url.toLowerCase()
    const i = url.indexOf('?')
    if (i > 0) {
      url = url.substring(0, i)
    }
    if (url.endsWith('.jpg') || url.endsWith('.jpeg')) {
      return 'image/jpeg'
    }
    if (url.endsWith('.png')) {
      return 'image/png'
    }
    if (url.endsWith('.heic')) {
      return 'image/heic'
    }
    if (url.endsWith('.pdf')) {
      return 'application/pdf'
    }
    if (url.endsWith('.csv') || url.endsWith('.tsv')) {
      return 'application/csv'
    }
    if (url.endsWith('.xls') || url.endsWith('.xlsx')) {
      return 'application/vns.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    if (url.endsWith('.doc') || url.endsWith('.docx')) {
      return 'application/vns.openxmlformats-officedocument.wordprocessingml.document'
    }
    if (url.endsWith('.json')) {
      return 'application/json'
    }
    if (url.endsWith('.xml')) {
      return 'text/xml'
    }
    return ''
  }

}

export default new FileManagementService()
