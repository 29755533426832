<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>

        <form ref="siteForm" @submit.prevent="handleSubmit" autocomplete="off">

          <div v-if="!isCreate" class="ps-2 mt-0 mb-3 pe-2 pb-2 add-border-bottom subtle">
            <ElementIdRow label="ID" :value="formData.id" :hideLabel="true" />
          </div>

          <TextInputRow label="Display Name" class="mt-0 pt-0"
            v-model="formData.displayName"
            labelClass="label-required"
            :required="true"
            placeholder="Sandy Shore"
            maxlength="72"
            dataType="description"
          />

         <SelectRow label="Customer" v-model="formData.customer" :required="true">
            <template v-slot:options>
              <option value="">-- Select --</option>
              <option v-for="customer in activeCustomers" :value="customer.id" :key="customer.id">
                {{ customer.displayName }}
              </option>
            </template>
          </SelectRow>

         <SelectRow label="Classification" v-model="formData.siteClassification" :required="true">
            <template v-slot:options>
              <option value="">-- Select --</option>
              <SiteClassifications />
            </template>
          </SelectRow>

          <SelectRow label="Status" v-model="formData.status" :required="true">
            <template v-slot:options>
              <option value="">-- Select --</option>
              <GeneralStatusOptions />
            </template>
          </SelectRow>

          <SelectRow label="Product" v-model="formData.product">
            <template v-slot:options>
              <option value="">-- Select --</option>
              <option v-for="product in products" :key="product.name" :value="product.name">{{ product.name }}</option>
            </template>
          </SelectRow>

          <TextAreaRow label="Notes" inputStyle="height: 70px;"
            v-model="formData.notes"
            placeholder="Enter any info about this Site that Agrology may find useful in the future."
            class="pb-0"
          />

          <CheckboxInputRow label="Monitoring"
            v-model="formData.envMonitoringStatus"
            checkboxLabel="Environmental Monitoring On"
            id="cbEnvMonitoringStatus"
            class="pb-0"
          />

          <CheckboxInputRow label=""
            v-model="formData.hwMonitoringStatus"
            checkboxLabel="Equipment Monitoring On"
            id="cbHWMonitoringStatus"
            class="pb-0"
          />

          <CheckboxInputRow label=""
            v-model="formData.grafanaEnabled"
            checkboxLabel="Visible in Grafana"
            id="cbGrafanaEnabled"
            labelClass="py-0"
          />

          <hr class="form-section" />

          <TextInputRow label="POC"
            v-model="formData.contactName"
            placeholder="Duke Ellington"
            maxlength="64"
          />

          <TextInputRow label="Phone"
            v-model="formData.phone"
            placeholder="888-555-1212"
            maxlength="18"
            dataType="phone"
          />

          <TextInputRow label="Email"
            v-model="formData.email"
            placeholder="me@awesome-domain.com"
            maxlength="48"
            dataType="email"
          />

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateSite()" v-if="!isCreate" :disabled="!canSubmit">Submit</button>
          <button class="btn" @click="createSite()" v-else :disabled="!canSubmit">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import FleetDataService from '@/services/fleet/FleetDataService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import Modal from '@/components/modals/Modal.vue'
import SiteClassifications from '@/components/selectOptions/SiteClassifications.vue'

import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'

export default {
  name: 'site-add-update',
  props: ['siteData'],
  extends: BaseFleetComponent,
  data () {
    return {
      isLoadingProducts: false,
      isLoadingSite: false,
      isBlocking: false,
      formData: {},
      isCreate: false,
      mode: '',
      products: null
    }
  },
  computed: {
    canSubmit () {
    return !(this.isDataLoading || !this.formData.displayName || !this.formData.customer ||
      !this.formData.siteClassification || !this.formData.status)
    },
    isDataLoading () {
      return this.isLoadingProducts || this.isLoadingSite
    },
    headerText () {
      if (this.siteData) {
        return 'Edit Site'
      }
      return 'Add New Site'
    }
  },
  methods: {
    reset () {
      this.mode = ''
      if (this.siteData) {
        this.formData = { ...this.siteData }
        if (this.siteData.products && this.siteData.products.length > 0) {
          this.formData.product = this.siteData.products[0]
        }
        if (this.formData.envMonitoringStatus) {
          this.formData.envMonitoringStatus = true
        } else {
          this.formData.envMonitoringStatus = false
        }
        if (this.formData.hwMonitoringStatus) {
          this.formData.hwMonitoringStatus = true
        } else {
          this.formData.hwMonitoringStatus = false
        }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.retrieveAllProducts()
      this.retrieveAllCustomers()
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    },
    retrieveAllProducts () {
      this.isLoadingProducts = true
      FleetDataService.listAllProducts()
        .then(response => {
          this.products = response.data
        })
        .catch(e => {
          let message = 'Failed to load Products List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoadingProducts = false
        })
    },
    updateSite () {
      this.isLoadingSite = true
      const id = this.formData.id.toLowerCase()
      this.formData.displayName = this.formData.displayName.trim()
      delete this.formData.id
      if (this.formData.notes) {
        this.formData.notes = this.formData.notes.trim()
      }
      if (this.formData.hwMonitoringStatus) {
        if (this.siteData.hwMonitoringStatus) {
          this.formData.hwMonitoringStatus = this.siteData.hwMonitoringStatus
        } else {
          this.formData.hwMonitoringStatus = 1
        }
      } else {
        this.formData.hwMonitoringStatus = 0
      }
      if (this.formData.envMonitoringStatus) {
        if (this.siteData.envMonitoringStatus) {
          this.formData.envMonitoringStatus = this.siteData.envMonitoringStatus
        } else {
          this.formData.envMonitoringStatus = 1
        }
      } else {
        this.formData.envMonitoringStatus = 0
      }
      if (this.formData.product) {
        this.formData.products = this.formData.product
        delete this.formData.product
      }
      FleetDataService.updateSite(id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Site Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          this.formData.id = id
          let message = 'Update Site Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingSite = false
        })
    },
    createSite () {
      this.isLoadingSite = true
      this.formData.displayName = this.formData.displayName.trim()
      if (this.formData.notes) {
        this.formData.notes = this.formData.notes.trim()
      }
      if (this.formData.hwMonitoringStatus) {
        if (this.siteData.hwMonitoringStatus) {
          this.formData.hwMonitoringStatus = this.siteData.hwMonitoringStatus
        } else {
          this.formData.hwMonitoringStatus = 1
        }
      } else {
        this.formData.hwMonitoringStatus = 0
      }
      if (this.formData.envMonitoringStatus) {
        if (this.siteData.envMonitoringStatus) {
          this.formData.envMonitoringStatus = this.siteData.envMonitoringStatus
        } else {
          this.formData.envMonitoringStatus = 1
        }
      } else {
        this.formData.envMonitoringStatus = 0
      }
      if (this.formData.product) {
        this.formData.products = this.formData.product
        delete this.formData.product
      }
      FleetDataService.createSite(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Site Created')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Site Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Site Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e.response)
        })
        .finally(() => {
          this.isLoadingSite = false
        })
    }
  },
  components: { Modal, TextInputRow, SelectRow, TextAreaRow, CheckboxInputRow, SiteClassifications, GeneralStatusOptions, ElementIdRow }
}
</script>
