<template>
  <div :class="classes">
    <span class="toolbar-button-text" :class="{'toolbar-button-active-text': isActive}" v-if="label">{{ label }}</span>
    <fa :icon="icon" class="toolbar-button-icon" :class="{'toolbar-button-active-icon': isActive}" v-else-if="icon" />
  </div>
</template>
<script>
export default {
  name: 'toolbar-button-control',
  props: ['label', 'icon', 'isActive', 'isDisabled'],
  computed: {
    classes () {
      const base = 'toolbar-button'
      if (this.isDisabled) {
        return `${base} toolbar-button-disabled`
      }
      if (this.isActive) {
        return `${base} toolbar-button-active`
      }
      return base
    }
  }
}
</script>
<style scoped>
  .toolbar-button {
    min-width: 30px;
    height: 30px;
    padding: 0px 4px;
    line-height: 30px;
    margin-right: 5px;
    border-radius: var(--input-border-radius);
    color: var(--toolbar-button-color);
    cursor: pointer;
    transition: background-color 0.15s ease;
  }
  .toolbar-section.toolbar-section-hoverable:hover .toolbar-button.toolbar-button-active {
    background-color: var(--toolbar-button-hover-bg-color);
  }
  .toolbar-button.toolbar-button-active {
    background-color: var(--toolbar-button-active-bg-color);
    cursor: default;
  }
  .toolbar-button.toolbar-button-active:hover {
    background-color: var(--toolbar-button-active-bg-color);
  }
  .toolbar-button:hover {
    background-color: var(--toolbar-button-hover-bg-color);
  }
  .toolbar-button:last-child {
    margin-right: 0;
  }
  .toolbar-button-icon {
    height: 18px;
    width: 18px;
    margin-top: -2px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .toolbar-button.toolbar-button-disabled {
    color: var(--app-inactive-color) !important;
    cursor: default;
  }
  .toolbar-section.toolbar-section-hoverable:hover .toolbar-button.toolbar-button-disabled:hover,
  .toolbar-button.toolbar-button-disabled:hover {
    background-color: inherit;
  }
  .toolbar-button-active-icon {
    color: var(--toolbar-button-active-color);
  }
  .toolbar-button-active-text {
    color: var(--toolbar-button-active-color);
  }
  .toolbar-button-text {
    font-size: var(--font-reduce-2);
    font-weight: 600;
  }
</style>