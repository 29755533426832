<template>

  <ModalSpreadsheet ref="spreadsheet" :sheetTitle="sheetTitle" :sheetData="sheetData" :isLoading="isLoading" :initialSort="sortCol" :initialSortReverse="sortReverse" :closer="sheetClosed" v-if="sheetTitle" />
  <SenetWidget ref="senetWidget" isInputMode="1" />
  <ModalBulkUpdateImport ref="modalBulkUpdateImport" />
  <ModalManageNodeLayouts ref="modalManageNodeLayouts" />
  <ModalSendSenetDownlink ref="senetDownlinkWidget" />
  <ModalNodeLayoutErrors ref="modalNodeLayoutErrors" />

  <div class="row justify-content-center mx-0 px-0 pt-3">

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-3" v-if="hasNWServers || hasMakes || isAdmin || hasProducts">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Additional Fleet Entities</h5>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="hasNWServers">
            <router-link :to="{  name: 'network-list' }" class="info-pane-item-link hoverable">Manage Network Servers</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="hasMakes">
            <router-link :to="{  name: 'manufacturer-list' }" class="info-pane-item-link hoverable">Manage Manufacturers &amp; Models</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="isAdmin">
            <router-link :to="{  name: 'firmware-list' }" class="info-pane-item-link hoverable">Manage Firmware</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="isAdmin">
            <router-link :to="{  name: 'chamber-configs-list' }" class="info-pane-item-link hoverable">Manage Chamber &amp; Vent Configs</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0 d-none d-lg-flex" v-if="isAdmin">
            <a @click="$refs.modalManageNodeLayouts.show" class="info-pane-item-link hoverable">Manage Node Layouts</a>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="isAdmin">
            <router-link :to="{  name: 'fleet-taz' }" class="info-pane-item-link hoverable">Manage Flashing Appliances (Taz)</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="hasProducts">
            <router-link :to="{  name: 'products-list' }" class="info-pane-item-link hoverable">Manage Products, Crops &amp; Varietals</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0">
            <a @click="$refs.modalNodeLayoutErrors.show" class="info-pane-item-link hoverable">Nodes With Layout Errors</a>
          </li>
        </ul>
      </template>
    </InfoPane>

    <div class="col me-3 d-none d-sm-block" style="max-width:0px;width:0px"/>

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-auto">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Fleet Inventory Tools</h5>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div>Agrology Device Manifest</div>
              <div class="mt-3 mb-3">
                <BasicButton caption="Spreadsheet" :disabled="isLoading" :action="getDevicesSS" />
                <BasicButton class="ms-3" icon="download" caption="CSV" :disabled="isLoading" :action="() => { getDevicesCSV(false) }" />
                <BasicButton class="ms-3" icon="download" caption="TSV" :disabled="isLoading" :action="() => { getDevicesCSV(true) }" />
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div>Agrology Gateway Manifest</div>
              <div class="mt-3 mb-3">
                <BasicButton caption="Spreadsheet" :disabled="isLoading" :action="getGatewaysSS" />
                <BasicButton class="ms-3" icon="download" caption="CSV" :disabled="isLoading" :action="() => { getGatewaysCSV(false) }" />
                <BasicButton class="ms-3" icon="download" caption="TSV" :disabled="isLoading" :action="() => { getGatewaysCSV(true) }" />
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div>Agrology Site Manifest</div>
              <div class="mt-3 mb-3">
                <BasicButton caption="Spreadsheet" :disabled="isLoading" :action="getSitesSS" />
                <BasicButton class="ms-3" icon="download" caption="CSV" :disabled="isLoading" :action="() => { getSitesCSV(false) }" />
                <BasicButton class="ms-3" icon="download" caption="TSV" :disabled="isLoading" :action="() => { getSitesCSV(true) }" />
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div>Agrology Node Manifest</div>
              <div class="mt-3 mb-3">
                <BasicButton caption="Spreadsheet" :disabled="isLoading" :action="getNodesSS" />
                <BasicButton class="ms-3" icon="download" caption="CSV" :disabled="isLoading" :action="() => { getNodesCSV(false) }" />
                <BasicButton class="ms-3" icon="download" caption="TSV" :disabled="isLoading" :action="() => { getNodesCSV(true) }" />
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item d-none d-lg-block">
            <div class="row mx-0 px-0 pt-3 pb-3 no-border-bottom justify-content-center">
              <div class="col-auto">
                <button class="btn" @click="$refs.modalBulkUpdateImport.show">Bulk Import / Update Devices</button>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </InfoPane>

    <div class="col me-3 d-none d-sm-block" style="max-width:0px;width:0px"/>

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-auto" v-if="hasSenet">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Senet Tools</h5>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div class="pb-1">Senet Device Manifest</div>

              <div class="mt-2 pt-2 add-border-top add-border-bottom mb-2 pb-2">
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPrivate" v-model="nz" value="private" >
                  <label class="form-check-label font-reduce-1" for="nzPrivate">
                    Private
                  </label>
                </div>
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPublic" v-model="nz" value="public" >
                  <label class="form-check-label font-reduce-1" for="nzPublic">
                    Public
                  </label>
                </div>
              </div>

              <div class="mt-3 mb-3">
                <BasicButton caption="Spreadsheet" :disabled="isLoading" :action="getSenetSpreadsheet" />
                <BasicButton class="ms-3" icon="download" caption="CSV" :disabled="isLoading" :action="getSenetDevicesCSV" />
                <BasicButton class="ms-3" icon="download" caption="TSV" :disabled="isLoading" :action="getSenetDevicesTSV" />
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item">
            <div class="row mx-0 px-0 pt-3 pb-3 no-border-bottom justify-content-center">
              <div class="col-auto">
                <BasicButton caption="Senet Device Manager Widget" :disabled="isLoading" :action="showSenetWidget" />
                <br />
                <!-- <a @click="showSenetDownlinkWidget()" class="btn mt-4">Send Downlinks via Senet</a> -->
              </div>
            </div>
          </li>
        </ul>
      </template>
    </InfoPane>

  </div>
</template>
<script>
import FleetDataService from '@/services/fleet/FleetDataService'

import InfoPane from '@/components/tables/InfoPane.vue'
import ModalSpreadsheet from '@/components/tables/ModalSpreadsheet'
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'

import SenetWidget from '@/components/fleet/SenetWidget.vue'
import ModalBulkUpdateImport from '@/components/fleet/ModalBulkUpdateImport.vue'
import ModalSendSenetDownlink from '@/components/fleet/ModalSendSenetDownlink.vue'
import ModalManageNodeLayouts from '@/components/fleet/ModalManageNodeLayouts.vue'
import ModalNodeLayoutErrors from '@/components/fleet/Misc/ModalNodeLayoutErrors.vue'
import BasicButton from '@/components/controls/BasicButton.vue'

export default {
  name: 'misc-main',
  components: { ModalSpreadsheet, InfoPane, SenetWidget, ModalBulkUpdateImport, ModalNodeLayoutErrors, ModalManageNodeLayouts, ModalSendSenetDownlink, BasicButton },
  extends: BaseFleetComponent,
  data () {
    return {
      senetUrlCSV: this.VUE_APP_API_URL + '/devices/senet/all/csv?download=senet_device',
      sheetTitle: '',
      sheetData: '',
      nz: 'public',
      isLoading: false
    }
  },
  computed: {
    sortCol () {
      if (['Agrology Device Manifest', 'Agrology Gateway Manifest'].includes(this.sheetTitle)) {
        return 1
      }
      if (['Agrology Site Manifest', 'Agrology Node Manifest'].includes(this.sheetTitle)) {
        return 3
      }
      return 0
    },
    sortReverse () {
      if (['Agrology Device Manifest', 'Agrology Gateway Manifest'].includes(this.sheetTitle)) {
        return true
      }
      return false
    }
  },
  methods: {
    sheetClosed () {
      this.sheetTitle = ''
    },
    downloadDataAsFile (data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    showSenetWidget () {
      this.$refs.senetWidget.show()
    },
    showSenetDownlinkWidget () {
      this.$refs.senetDownlinkWidget.show()
    },
    getDevicesSS () {
      this.isLoading = true
      this.sheetTitle = 'Agrology Device Manifest'
      this.$nextTick(() => {
        this.$refs.spreadsheet.show()
        FleetDataService.getDeviceExportCSV(true)
          .then(response => {
            this.sheetData = response.data
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    getGatewaysSS () {
      this.isLoading = true
      this.sheetTitle = 'Agrology Gateway Manifest'
      this.$nextTick(() => {
        this.$refs.spreadsheet.show()
        FleetDataService.getGatewayExportCSV(true)
          .then(response => {
            this.sheetData = response.data
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    getSitesSS () {
      this.isLoading = true
      this.sheetTitle = 'Agrology Site Manifest'
      this.$nextTick(() => {
        this.$refs.spreadsheet.show()
        FleetDataService.getSiteExportCSV(true)
          .then(response => {
            this.sheetData = response.data
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    getNodesSS () {
      this.isLoading = true
      this.sheetTitle = 'Agrology Node Manifest'
      this.$nextTick(() => {
        this.$refs.spreadsheet.show()
        FleetDataService.getNodeExportCSV(true)
          .then(response => {
            this.sheetData = response.data
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    getSenetSpreadsheet () {
      this.isLoading = true
      this.sheetTitle = 'Senet Device Manifest'
      this.$nextTick(() => {
        this.$refs.spreadsheet.show()
        FleetDataService.getAllSenetDevicesCSV(this.nz, true, true)
          .then(response => {
            this.sheetData = response.data
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    getSenetDevicesCSV () {
      this.isLoading = true
      FleetDataService.getAllSenetDevicesCSV(this.nz, true, false)
        .then(response => {
          this.downloadDataAsFile(response.data, 'senet_devices.csv')
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSenetDevicesTSV () {
      this.isLoading = true
      FleetDataService.getAllSenetDevicesCSV(this.nz, true, true)
        .then(response => {
          this.downloadDataAsFile(response.data, 'senet_devices.tsv')
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getDevicesCSV (asTSV) {
      this.isLoading = true
      FleetDataService.getDeviceExportCSV(asTSV)
        .then(response => {
          this.downloadDataAsFile(response.data, `agrology_devices.${asTSV ? 't' : 'c'}sv`)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSitesCSV (asTSV) {
      this.isLoading = true
      FleetDataService.getSiteExportCSV(asTSV)
        .then(response => {
          this.downloadDataAsFile(response.data, `agrology_sites.${asTSV ? 't' : 'c'}sv`)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getNodesCSV (asTSV) {
      this.isLoading = true
      FleetDataService.getNodeExportCSV(asTSV)
        .then(response => {
          this.downloadDataAsFile(response.data, `agrology_nodes.${asTSV ? 't' : 'c'}sv`)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getGatewaysCSV (asTSV) {
      this.isLoading = true
      FleetDataService.getGatewayExportCSV(asTSV)
        .then(response => {
          this.downloadDataAsFile(response.data, `agrology_gateways.${asTSV ? 't' : 'c'}sv`)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}

</script>
