<template>
  <div>
    <DataTable
      class="dt-info-pane-sm"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      routerLink="gateway-add"
      actionIcon="plus"
      itemType="Gateway"
      noDataMessage="No Gateways"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'

export default {
  name: 'gateways-by-site-list',
  extends: BaseDeviceComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'GW EUI', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Make', sort: 2 },
        { text: 'Model', sort: 3, class: 'd-none d-sm-block' },
        { icon: 'power-off', sort: 4 },
        { icon: 'globe', sort: 5 },
        { text: 'GPS', sort: 6 },
        { text: 'LoRa', sort: 7 }
      ],
      entityTypeName: this.entityType,
      loading: false
    }
  },
  props: ['entityType', 'siteData', 'siteGateways'],
  computed: {
    activeSiteGateways () {
      if (this.$state.showInactiveNodes) {
        return this.siteGateways
      }
      var active = []
      if (!this.siteGateways) {
        return active
      }
      for (const gateway of this.siteGateways) {
        if (gateway.status === 'inactive') {
          continue
        }
        active.push(gateway)
      }
      return active
    },
    listTitle () {
      return 'Gateways for this ' +
        this.siteClassification.charAt(0).toUpperCase() + this.siteClassification.slice(1)
    },
    siteClassification () {
      if (this.siteData && this.siteData.siteClassification) {
        return this.siteData.siteClassification
      }
      return this.entityTypeName
    },
    rows () {
      const out = []
      if (this.activeSiteGateways && this.activeSiteGateways.length > 0) {
        for (const gw of this.activeSiteGateways) {
          out.push([
            { value: '…' + this.getLastN(gw.id, 8), route: 'gateway-details', params: { id: gw.id } },
            {
              value: this.getSince(gw.report_time),
              class: {
                notable: !gw.monitoringMuted && this.isTimeNotable(gw.report_time),
                'status-error': !gw.monitoringMuted && this.isTimeAlertable(gw.report_time),
                'status-caution': !gw.monitoringMuted && this.isTimeCautionable(gw.report_time)
              },
              sortValue: Date.now() - gw.report_time
            },
            { value: gw.manufacturer },
            { value: gw.model, class: 'd-none d-sm-block' },
            { value: this.powerSourceText(gw.powerSource), sortValue: gw.powerSource, leftIcon: this.powerSourceIcon(gw.powerSource) },
            { value: this.connText(gw.networkAccess), sortValue: gw.networkAccess, leftIcon: this.connIcon(gw.networkAccess) },
            { value: this.formatHasGPS(gw), leftIcon: this.formatHasGPSIcon(gw), class: { 'dt-centered': true, 'status-danger': !this.formatHasGPS(gw) } },
            { value: gw.networkServer }
          ])
        }
      }
      return out
    }
  },
  methods: {
    formatHasGPSIcon (elem) {
      if (elem && elem.lat && elem.long) {
        return ''
      }
      return 'exclamation'
    },
    formatHasGPS (elem) {
      if (elem && elem.lat && elem.long) {
        return '✓'
      }
      return ''
    },
    powerSourceIcon (powerSource) {
      if (!powerSource) {
        return ''
      }
      if (powerSource === 'wired') {
        return 'plug'
      }
      if (powerSource === 'solar') {
        return 'sun'
      }
      return ''
    },
    powerSourceText (powerSource) {
      if (!powerSource) {
        return '-'
      }
      if (powerSource === 'wired') {
        return ''
      }
      if (powerSource === 'solar') {
        return ''
      }
      return powerSource
    },
    connIcon (conn) {
      if (!conn) {
        return ''
      }
      if (conn === 'cellular') {
        return 'signal'
      }
      if (conn === 'wifi') {
        return 'wifi'
      }
      if (conn === 'ethernet') {
        return 'ethernet'
      }
      return ''
    },
    connText (conn) {
      if (!conn) {
        return '-'
      }
      if (conn === 'cellular') {
        return ''
      }
      if (conn === 'solar') {
        return ''
      }
      if (conn === 'ethernet') {
        return ''
      }
      return conn
    }
  },
  components: { DataTable }
}
</script>
