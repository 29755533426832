<template>
  <div class="filemanager-thumbnail-item" v-if="file" @click="showViewer">
    <div class="filemanager-thumbnail-image">
      <img :src="file.s3ThumbUrl" v-if="file.s3ThumbUrl" />
      <div class="filemanager-thumbnail-placeholder" v-else>
        <fa class="filemanager-thumbnail-placeholder-icon" :icon="placeholderIcon" />
      </div>
    </div>
    <div class="filemanager-thumbnail-filename">
      {{ file.filename }}
    </div>
  </div>
  <OmniViewer
    :file="file"
    :showEditorFunc="editorFunc"
    ref="thumbnailOmniViewer"
  />
</template>
<script>
import OmniViewer from '@/components/controls/Viewers/OmniViewer.vue'

export default {
  name: 'file-manager-thumbnail',
  props: ['file', 'showEditorFunc', 'autoView'],
  components: { OmniViewer },
  data () {
    return {
      viewerVisible: false
    }
  },
  methods: {
    showViewer () {
      this.$refs.thumbnailOmniViewer.show()
    }
  },
  computed: {
    canEdit () {
      return true
    },
    editorFunc () {
      if (!this.showEditorFunc || !this.canEdit) {
        return undefined
      }
      return this.showEditorFunc
    },
    isImage () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['png', 'heic', 'jpg'].includes(this.file.fileType)
    },
    isVideo () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['mp4', 'mov'].includes(this.file.fileType)
    },
    isSpreadsheet () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['csv', 'tsv'].includes(this.file.fileType)
    },
    placeholderIcon () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return 'circle-question'
      }
      if (this.isImage) {
        return 'image'
      }
      if (this.isVideo) {
        return 'video'
      }
      if (this.isSpreadsheet) {
        return 'file-csv'
      }
      if (this.file.fileType === 'pdf') {
        return 'file-pdf'
      }
      return 'circle-question'
    }
  },
  mounted () {
    if (this.autoView) {
      this.$nextTick(() => {
        this.showViewer()
      })
    }
  }
}
</script>
<style scoped>
  .filemanager-thumbnail-item {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    padding: 10px 8px;
    text-align: center;
    min-width: 134px;
    max-width: 134px;
    border-radius: var(--form-border-radius);
  }

  .filemanager-thumbnail-item:hover {
    background-color: #6663;
    cursor: pointer;
  }

  .filemanager-thumbnail-item:hover .filemanager-thumbnail-filename {
    text-decoration: underline;
  }

  .filemanager-thumbnail-image {
    height: 96px;
    min-height: 96px;
    max-height: 96px;
    position: relative;
  }

  .filemanager-thumbnail-image img {
    border: solid #444444cc 1px;
    max-height: 96px;
    max-width: 100%;
    height: auto;
    width: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .filemanager-thumbnail-placeholder {
    border: solid #444444cc 1px;
    background-color: var(--app-bg-color);
    min-height: 96px;
    min-width: 96px;
    max-height: 96px;
    max-width: 96px;
    margin: 0 auto;
  }

  .filemanager-thumbnail-placeholder-icon {
    color: var(--app-subtle-color);
    padding: 20px;
  }

  .filemanager-thumbnail-filename {
    max-width: 120px;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-reduce-4);
    margin-top: 5x;
    color: var(--app-link-color);
  }
</style>