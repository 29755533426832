<template>
  <template v-if="viewer !== 'none' && file">
    <ImageViewer
      v-if="viewer === 'image'"
      ref="modalImageViewer"
      :showEditorFunc="showEditorFunc"
      :file="file"
      :closer="close"
    />
    <VideoViewer
      v-if="viewer === 'video'"
      ref="modalVideoViewer"
      :showEditorFunc="showEditorFunc"
      :file="file"
      :closer="close"
    />
    <SpreadsheetViewer
      v-if="viewer === 'csv'"
      ref="modalSpreadsheetViewer"
      :showEditorFunc="showEditorFunc"
      :file="file"
      :closer="close"
    />
    <PDFViewer
      v-if="viewer === 'pdf'"
      ref="modalPDFViewer"
      :showEditorFunc="showEditorFunc"
      :file="file"
      :closer="close"
    />
  </template>
</template>
<script>
import ImageViewer from '@/components/controls/Viewers/ImageViewer.vue'
import VideoViewer from '@/components/controls/Viewers/VideoViewer.vue'
import SpreadsheetViewer from '@/components/controls/Viewers/SpreadsheetViewer.vue'
import PDFViewer from '@/components/controls/Viewers/PDFViewer.vue'

export default {
  name: 'file-manager-list-item',
  props: ['file', 'showEditorFunc', 'closer'],
  components: { ImageViewer, SpreadsheetViewer, PDFViewer, VideoViewer },
  data () {
    return {
      oldRoute: ''
    }
  },
  computed: {
    titleText () {
      if (this.file && this.file.filename) {
        return this.file.filename
      }
      if (this.viewer === 'csv') {
        return 'Untitled Spreadsheet'
      }
      if (this.viewer === 'pdf') {
        return 'Untitled PDF'
      }
      if (this.viewer === 'image') {
        return 'Untitled Image'
      }
      if (this.viewer === 'video') {
        return 'Untitled Video'
      }
      return 'Untitled File'
    },
    isImage () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['png', 'heic', 'jpg'].includes(this.file.fileType)
    },
    isVideo () {
      if (!this.file || !this.file.fileType || !this.file.s3url) {
        return false
      }
      return ['mp4', 'mov'].includes(this.file.fileType)
    },
    viewer () {
      if (this.isImage) {
        return 'image'
      }
      if (this.isVideo) {
        return 'video'
      }
      if (this.file.fileType === 'csv') {
        return 'csv'
      }
      if (this.file.fileType === 'tsv') {
        return 'csv'
      }
      if (this.file.fileType === 'pdf') {
        return 'pdf'
      }
      return 'none'
    }
  },
  methods: {
    show () {
      let suffix = undefined
      if (this.viewer === 'image') {
        suffix = 'view'
        this.$refs.modalImageViewer.show()
      } else if (this.viewer === 'pdf') {
        suffix = 'view'
        this.$refs.modalPDFViewer.show()
      } else if (this.viewer === 'csv') {
        suffix = 'view'
        this.$refs.modalSpreadsheetViewer.show()
      } else if (this.viewer === 'video') {
        suffix = 'view'
        this.$refs.modalVideoViewer.show()
      }
      if (this.showEditorFunc && this.file && !suffix) {
        suffix = 'edit'
        this.showEditorFunc(this.file)
      }
      if (!suffix) {
        return
      }

      let path = window.location.hash
      let st = path.indexOf('/file/')
      if (st > 0) {
        path = path.substring(0, st)
      } else if (this.file && this.file.id) {
        history.pushState(
          {},
          null,
          `${path}/file/${this.file.id}/${suffix}`
        )
      }
      this.oldRoute = path
    },
    close () {
      if (this.oldRoute) {
        history.pushState(
          {},
          null,
          this.oldRoute
        )
      }
      if (this.closer) {
        this.closer()
      }
    }
  }
}
</script>